function draw() {
    ctx.clearRect(0,0, CANVAS_WIDTH, CANVAS_HEIGHT);

    if (autoRotate.z) rotate((rotateSpeed * autoRotate.z) * Math.PI * 2 * 0.01, 'x', 'y');
    if (autoRotate.x) rotate((rotateSpeed * autoRotate.x) * Math.PI * 2 * 0.01, 'y', 'z');
    if (autoRotate.y) rotate((rotateSpeed * autoRotate.y) * Math.PI * 2 * 0.01, 'x', 'z');

    for (let edge of edges) {
        ctx.beginPath();
        ctx.moveTo(vertices[edge[0]].x,vertices[edge[0]].y);
        ctx.lineTo(vertices[edge[1]].x,vertices[edge[1]].y);
        ctx.stroke();
    }

    if (!running) return;

    requestAnimationFrame(draw);
}

let canvas;
let ctx;

let autoRotate = {
    x: false,
    y: false,
    z: false,
}
let rotateSpeed = 0.2;
let lineColor = 'blue';
let running = false;
const CANVAS_HEIGHT = 500;
const CANVAS_WIDTH = 400;
const POINT3D = function(x, y, z) {this.x = x; this.y = y; this.z = z};

let viewPoint = {
    x: CANVAS_WIDTH / 2,
    y: CANVAS_HEIGHT / 2,
    z: 0,
}
let cubeSize = 100;
let objects = [
    {   
        name: 'Cube',
        vertices: [
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y - cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y - cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y + cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y + cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y - cubeSize, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y - cubeSize, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y + cubeSize, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y + cubeSize, viewPoint.z + cubeSize),
        ],
        edges: [
            [0, 1], [1, 2], [2, 3], [3, 0], //back
            [4, 5], [5, 6], [6, 7], [7, 4], //front
            [0, 4], [1, 5], [2, 6], [3, 7], //connections
        ],
    },
    {
        name: 'Pyramid',
        vertices: [
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y - cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y - cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y + cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y + cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x, viewPoint.y, viewPoint.z + cubeSize),
        ],
        edges: [
            [0, 1], [1, 2], [2, 3], [3, 0], //back
            [0, 4], [1, 4], [2, 4], [3, 4], //to point
        ],
    },
    {
        name: 'Cone',
        vertices: [
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y - cubeSize, viewPoint.z),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y - cubeSize, viewPoint.z),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y + cubeSize, viewPoint.z),
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y + cubeSize, viewPoint.z),
            new POINT3D(viewPoint.x, viewPoint.y, viewPoint.z + cubeSize * 2),
            new POINT3D(viewPoint.x, viewPoint.y, viewPoint.z - cubeSize * 2),
        ],
        edges: [
            [0, 1], [1, 2], [2, 3], [3, 0], //back
            [0, 4], [1, 4], [2, 4], [3, 4], //to point
            [0, 5], [1, 5], [2, 5], [3, 5], //to point
        ],
    },
    {
        name: 'Diamond',
        vertices: [
            new POINT3D(viewPoint.x + 50, viewPoint.y - 50, viewPoint.z),
            new POINT3D(viewPoint.x + 25 , viewPoint.y - 50, viewPoint.z + 43),
            new POINT3D(viewPoint.x - 25, viewPoint.y - 50, viewPoint.z + 43),
            new POINT3D(viewPoint.x - 50, viewPoint.y - 50, viewPoint.z),
            new POINT3D(viewPoint.x - 25, viewPoint.y - 50, viewPoint.z - 43),
            new POINT3D(viewPoint.x + 25, viewPoint.y - 50, viewPoint.z - 43),

            new POINT3D(viewPoint.x + 100, viewPoint.y, viewPoint.z),
            new POINT3D(viewPoint.x + 50 , viewPoint.y, viewPoint.z + 86),
            new POINT3D(viewPoint.x - 50, viewPoint.y, viewPoint.z + 86),
            new POINT3D(viewPoint.x - 100, viewPoint.y, viewPoint.z),
            new POINT3D(viewPoint.x - 50, viewPoint.y, viewPoint.z - 86),
            new POINT3D(viewPoint.x + 50, viewPoint.y, viewPoint.z - 86),

            new POINT3D(viewPoint.x, viewPoint.y + 150, viewPoint.z),
        ],
        edges: [
            [0,1], [1,2], [2,3], [3,4], [4,5], [5,0], // top hexagon
            [6,7], [7,8], [8,9], [9,10], [10,11], [11, 6], // bottom hexagon
            [0,6], [1,7], [2,8], [3,9], [4,10], [5,11], // hexagon join
            [6,12], [7,12], [8,12], [9,12], [10,12], [11, 12],  // point join
        ],
    },
    {
        name: 'House',
        vertices: [
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y - cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y - cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y + cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y + cubeSize, viewPoint.z - cubeSize),
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y - cubeSize, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y - cubeSize, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y + cubeSize, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x - cubeSize, viewPoint.y + cubeSize, viewPoint.z + cubeSize),

            new POINT3D(viewPoint.x - cubeSize, viewPoint.y - cubeSize * 1.5, viewPoint.z),
            new POINT3D(viewPoint.x + cubeSize, viewPoint.y - cubeSize * 1.5, viewPoint.z),

            new POINT3D(viewPoint.x - cubeSize / 6, viewPoint.y + cubeSize, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x - cubeSize / 6, viewPoint.y + cubeSize / 4, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x - cubeSize / 1.5, viewPoint.y + cubeSize / 4, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x - cubeSize / 1.5, viewPoint.y + cubeSize, viewPoint.z + cubeSize),

            new POINT3D(viewPoint.x + cubeSize / 6, viewPoint.y + cubeSize / 1.5, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x + cubeSize / 6, viewPoint.y + cubeSize / 4, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x + cubeSize / 1.5, viewPoint.y + cubeSize / 4, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x + cubeSize / 1.5, viewPoint.y + cubeSize / 1.5, viewPoint.z + cubeSize),

            new POINT3D(viewPoint.x + cubeSize / 6, viewPoint.y - cubeSize / 1.5, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x + cubeSize / 6, viewPoint.y - cubeSize / 4, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x + cubeSize / 1.5, viewPoint.y - cubeSize / 4, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x + cubeSize / 1.5, viewPoint.y - cubeSize / 1.5, viewPoint.z + cubeSize),

            new POINT3D(viewPoint.x - cubeSize / 6, viewPoint.y - cubeSize / 1.5, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x - cubeSize / 6, viewPoint.y - cubeSize / 4, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x - cubeSize / 1.5, viewPoint.y - cubeSize / 4, viewPoint.z + cubeSize),
            new POINT3D(viewPoint.x - cubeSize / 1.5, viewPoint.y - cubeSize / 1.5, viewPoint.z + cubeSize),
            
        ],
        edges: [
            [0, 1], [1, 2], [2, 3], [3, 0], //back
            [4, 5], [5, 6], [6, 7], [7, 4], //front
            [0, 4], [1, 5], [2, 6], [3, 7], //connections
            [8,9], [0,8], [4,8], [1,9], [5,9], // roof
            [10,11], [11,12], [12,13], //door
            [14,15], [15,16], [16,17], [17,14], //gfloor window
            [18,19], [19,20], [20,21], [21,18], //1floor right window
            [22,23], [23,24], [24,25], [25,22], //1floor left window

        ],
    },
    {
        name: 'Tennis',
        vertices: [
            new POINT3D(viewPoint.x - 69, viewPoint.y - 150, viewPoint.z),
            new POINT3D(viewPoint.x + 69, viewPoint.y - 150, viewPoint.z),
            new POINT3D(viewPoint.x + 69, viewPoint.y + 150, viewPoint.z),
            new POINT3D(viewPoint.x - 69, viewPoint.y + 150, viewPoint.z),

            new POINT3D(viewPoint.x + 60, viewPoint.y + 150, viewPoint.z),
            new POINT3D(viewPoint.x + 60, viewPoint.y - 150, viewPoint.z),
            new POINT3D(viewPoint.x - 60, viewPoint.y + 150, viewPoint.z),
            new POINT3D(viewPoint.x - 60, viewPoint.y - 150, viewPoint.z),

            new POINT3D(viewPoint.x + 60, viewPoint.y, viewPoint.z),
            new POINT3D(viewPoint.x - 60, viewPoint.y, viewPoint.z),

            new POINT3D(viewPoint.x + 60, viewPoint.y - 60, viewPoint.z),
            new POINT3D(viewPoint.x - 60, viewPoint.y - 60, viewPoint.z),

            new POINT3D(viewPoint.x + 60, viewPoint.y + 60, viewPoint.z),
            new POINT3D(viewPoint.x - 60, viewPoint.y + 60, viewPoint.z),

            new POINT3D(viewPoint.x, viewPoint.y + 60, viewPoint.z),
            new POINT3D(viewPoint.x, viewPoint.y - 60, viewPoint.z),

            new POINT3D(viewPoint.x - 69, viewPoint.y, viewPoint.z),
            new POINT3D(viewPoint.x + 69, viewPoint.y, viewPoint.z),
            new POINT3D(viewPoint.x + 69, viewPoint.y, viewPoint.z - 7),
            new POINT3D(viewPoint.x - 69, viewPoint.y, viewPoint.z - 7),
        ],
        edges: [
            [0, 1], [1, 2], [2, 3], [3, 0], //back
            [4, 5], [6, 7], //tramline
            [8,9], //net line
            [10,11], //serve line
            [12,13], //serve line
            [14,15], // centre line
            [16,17], [18,19], [17,18], [16, 19] //net
        ],
    },
];

function getObjectsValues() {
    return {
        names: objects.map(object =>
            object.name
        ),
        length: objects.length,
    }
}

let vertices;
let edges;


function viewChange(changes) {
    if (changes.rotate) setRotate(changes.rotate, changes.direction);
    if (changes.view) moveView(changes.move, changes.view);
    if (changes.autoAxis) autoRotate[changes.autoAxis] = changes.autoDirection;
    if (changes.object !== undefined) setObject(changes.object);
}


function setRotate(axis, direction) {
    if (axis === 'x') rotate(direction * Math.PI * 2 * 0.01, 'y', 'z');
    if (axis === 'y') rotate(direction * Math.PI * 2 * 0.01, 'x', 'z');
    if (axis === 'z') rotate(direction * Math.PI * 2 * 0.01, 'x', 'y');
}

function rotate(angle, axis1, axis2) {
    for (let v of vertices) {
        let axis1Value = v[axis1] - viewPoint[axis1];
        let axis2Vale = v[axis2] - viewPoint[axis2];
        let axis1Change = axis1Value * Math.cos(angle) - axis2Vale * Math.sin(angle);
        let axis2Change = axis1Value * Math.sin(angle) + axis2Vale * Math.cos(angle);
        v[axis1]  = axis1Change + viewPoint[axis1];
        v[axis2] = axis2Change + viewPoint[axis2];
    }
}

function moveView(move, axis) {
    vertices.forEach(point => {
        point[axis] += move;
    });
}

function setObject(objectNum) {
    vertices = objects[objectNum].vertices;
    edges = objects[objectNum].edges; 
}

function cleanUp() {
    running = false;
}

function setUp(object = 0) {
    if (running) {
        console.log('An instance of this programme is already running')
        return;
    }
    running = true,
    canvas = document.getElementById('render-layer');
    ctx = canvas.getContext('2d');
    canvas.width = CANVAS_WIDTH;
    canvas.height = CANVAS_HEIGHT;
    ctx.strokeStyle = lineColor;
    ctx.lineWidth = 2;
    ctx.lineCap = 'round';
    autoRotate = {
        x: false,
        y: false,
        z: false,
    }
    setObject(object);
    draw();
}

export default setUp;
export {cleanUp, viewChange, getObjectsValues};