<template>
    <div class="checkboxes">
        <div class="checkbox-container">
            <img @click="checked(1)" class="checkbox" :src="require(`@/assets/images/${topImage}.svg`)" />
        </div>
        <div class="checkbox-container">
            <img @click="checked(-1)" class="checkbox" :src="require(`@/assets/images/${bottomImage}.svg`)" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'RotateSelector',
    data() {
        return {
            selectValue: 0,
        }
    },
    props: {
        axis: {
            type: String,
            required: true,
        },
    },
    computed: {
        topImage() {
            return this.selectValue === 1 ? 'checkTicked' : 'checkUnticked' ;
        },
        bottomImage() {
            return this.selectValue === -1 ? 'checkTicked' : 'checkUnticked' ;
        }
    },
    methods: {
        checked(selecter = 0) {
            if (selecter === this.selectValue) {
                this.selectValue = 0;
                this.$emit('rotationSet', this.axis, this.selectValue);
                return;
            }
            if (selecter != this.selectValue) this.selectValue = selecter;
            this.$emit('rotationSet', this.axis, this.selectValue);
        }
    }
}
</script>

<style scoped>
    .checkboxes {
        display: flex;
        flex-direction: column;
        background: white;
    }

    .checkbox {
        width: 25px;
        padding: 0px 2px;
        cursor: pointer;
    }

    .checkbox-container {
        height: 50px;
        width: 30px;
        display: flex;
        justify-content: center;
        border: 1px solid gray;
    }
</style>