<template>
    <div @click="actionFunction" class="rotation-container">
        <img class="image" :src="image" />
    </div>
</template>

<script>
export default {
    name: 'RotationButton',
    props: {
        imageString: {
            type: String,
            default: '',
        },
    },
    methods: {
        actionFunction() {
            this.$emit('actionChange');
        }
    },
    data() {
        return {
            image: require(`@/assets/images/ObjectsRender/${this.imageString}`),
        }
    }
}
</script>

<style scoped>
.rotation-container {
    background: rgb(232, 229, 229);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    border: 1px solid gray;
    cursor: pointer;
}

.image{
    width: 100%;
    height: 100%;
}
</style>