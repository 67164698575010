<template>
    <div id="render-container">
        <div class="canvas-container">
            <canvas id="render-layer"></canvas>
        </div>
        <div class="buttons-container">
            <div class="column-container">
                <div class="header"> Y-Axis</div>
                <div class="axis-controls">
                    <div>
                        <RotationButton @actionChange="rotate('y', 1)" :imageString="'yPosTurn.svg'"/>
                        <RotationButton @actionChange="rotate('y', -1)" :imageString="'yNegTurn.svg'"/>
                    </div>
                    <RotateSelector :axis="'y'" @rotationSet="autoRotate"/>
                </div>
            </div>
            <div class="column-container">
                <div class="header"> Z-Axis</div>
                <div class="axis-controls">
                    <div>
                        <RotationButton @actionChange="rotate('z', 1)" :imageString="'zPosTurn.svg'"/>
                        <RotationButton @actionChange="rotate('z', -1)" :imageString="'zNegTurn.svg'"/>
                    </div>
                    <RotateSelector :axis="'z'" @rotationSet="autoRotate"/>
                </div>
            </div>
            <div class="column-container">
                <div class="header"> X-Axis</div>
                <div class="axis-controls">
                    <div>
                        <RotationButton @actionChange="rotate('x', -1)" :imageString="'xPosTurn.svg'"/>
                        <RotationButton @actionChange="rotate('x', 1)" :imageString="'xNegTurn.svg'"/>
                    </div>
                    <RotateSelector :axis="'x'" @rotationSet="autoRotate"/>
                </div>
            </div>

            <div class="column-container">
                <div class="header" >Move Camera</div>
                <div class="movement-arrows">
                    <div class="side-arrow move-button" @click="moveCamera('x', -5)">
                        <img class="arrow-image left" src="@/assets/images/selectArrow.svg">
                    </div>
                    <div class="mid-arrows-container">
                        <div class="mid-arrow move-button" @click="moveCamera('y', -5)">
                            <img class="arrow-image up" src="@/assets/images/selectArrow.svg">
                        </div>
                        <div class="mid-arrow move-button" @click="moveCamera('y', 5)">
                            <img class="arrow-image" src="@/assets/images/selectArrow.svg">
                        </div>
                    </div>
                    <div class="side-arrow move-button" @click="moveCamera('x', 5)">
                        <img class="arrow-image right" src="@/assets/images/selectArrow.svg">
                    </div>
                </div>
            </div>

            <div class="column-container">
                <div class="header" >Set Object</div>
                <div class="display-name">{{ objectName }}</div>
                <div class="object-change-container">
                    <div class="object-change-button" @click="changeObject(-1)">Prev.</div>
                    <div class="object-change-button" @click="changeObject(1)">Next</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import RenderObjects, {cleanUp, viewChange, getObjectsValues} from "../gameFiles/3dObjects";
import RotationButton from '@/components/RotationButton.vue';
import RotateSelector from '@/components/page_elements/RotateSelector.vue';

export default {
    name: 'Objects3D',
    components: {
        RotationButton,
        RotateSelector,
    },
    data() {
        return {
            selectedObject: 0,
            objectData: getObjectsValues(),
        }
    },
    computed: {
        objectName() {
            return this.objectData.names[this.selectedObject];
        }
    },
    methods: {
        beginRender() {
            RenderObjects();
       },
       rotate(axis, direction) {
        viewChange({rotate: axis, direction: direction});
       },
       moveCamera(axis, direction) {
        viewChange({view: axis, move: direction});
       },
       autoRotate(axis, direction ) {
        viewChange({autoAxis: axis, autoDirection: direction});
       },
       changeObject(change) {
        this.selectedObject += change;
        if (this.selectedObject > this.objectData.length - 1) this.selectedObject = 0;
        if (this.selectedObject < 0) this.selectedObject = this.objectData.length - 1;
        viewChange({object: this.selectedObject});
       },
    },
    beforeDestroy() {
        cleanUp();
    },
    async mounted() {
        this.beginRender(this.selectedObject);
    }
}
</script>

<style scoped>
.canvas-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

#render-layer {
    background: black;
}

.buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.axis-controls {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.header {
    width: 100%;
    color: black;
    background: grey;
    text-align: center;
    margin-bottom: 2px;
}

.column-container {
    margin: 0px 5px;
}

.mid-arrows-container {
    display: flex;
    flex-direction: column;
}

.mid-arrow {
    height: 50px;
    width: 50px;
}

.up {
    transform: rotate(180deg);
}

.left {
    transform: rotate(90deg);
}

.right {
    transform: rotate(-90deg);
}

.movement-arrows {
  display: flex;  
}

.side-arrow {
    height: 100px;
    width: 50px;
}

.arrow-image {
    width: 30px;
}

.move-button {
    background: rgb(232, 229, 229);
    display: flex;
    justify-content: center;
    border: 1px solid gray;
    cursor: pointer;
}

.object-change-container {
    display: flex;
}

.object-change-button {
    height: 50px;
    width: 50%;
    background: rgb(232, 229, 229);
    display: flex;
    justify-content: center;
    border: 1px solid gray;
    cursor: pointer;  
}

.display-name {
    background: white;
    font-size: 20px;
    text-align: center;
    vertical-align: middle;
    width: 140px;
    height: 50px;
}

</style>