<template>
    <div>
        <ProjectPageTemplate
            projectTitle="3D OBJECTS"
            :imageSource="require('@/assets/images/projectLinks/objectRenderLink.png')"
            shortDescription="Rendering different wire frame objects in 3D in an HTML canvas. Rotate the object in the X, Z and Y axis, either step by step or lock rotation direction. Move the camera point with the arrow buttons. Different shapes can be selected."
            :longDescription="description"
            :importedLinkData="techLinksData"
        >
        <template v-slot:main-content>
            <Objects3D></Objects3D>

        </template>
        </ProjectPageTemplate>
    </div>
</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import Objects3D from '../components/3dObjects.vue';
import techLinks from '@/data/techLinks.js'

export default {
    name: 'ObjectRender',
    components: {
        ProjectPageTemplate,
        Objects3D,
    },
    data() {
        return {
            description: `Using a few different YouTube videos tutorials created this project to render and rotate vertices on a canvas. Image can be rotated in the 3 different axis's. This was a useful project to gain some understanding of how to draw and change the view of a 3D object.`,
            techLinksData: [
              techLinks.javascript,
            ],
        }
      },
  }
</script>